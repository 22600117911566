import { useElementSize } from '@charlietango/hooks'
import { ConfigurableProduct, useProductCardDetailsQuery, ProductInterface } from '@magentoTypes'
import { useTranslation } from 'next-i18next'
import { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { AddToWishlist } from '~/elements/AddToWishlist/AddToWishlist'
import { Link } from '~/elements/Link/Link'
import { DataLayerEnum, useGTM } from '~/hooks/useGTM'
import { ColorThumbs } from './ColorThumbs'
import { useRouter } from 'next/router'
import { useBrandType } from '~/hooks/useBrandType'
import clsx from 'clsx'
import { useProductAttributes } from '~/hooks/useProductAttributes'
import ProductCardBadges from './ProductCardBadges'
import ProductCardTop from './ProductCardTop'
import FeaturesSection from './FeaturesSection'
import PriceSection from './PriceSection'
import SampleButton from './SampleButton'
import { useQueryClient } from '@tanstack/react-query'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createMagentoClient } from '~/graphql/magentoClient'
import Loader from './Loader'
import {
  checkHasSustainability,
  checkIsCotton,
  checkIsHandmade,
  checkIsWashable,
  checkIsWolle,
} from '~/lib/getProductFeature'
import { getCioProductPrice, getCioVariationId } from '~/lib/cioHelpers'
import { RatingStars } from '~/elements/RatingStars/RatingStars'

type Props = {
  product: ConfigurableProduct
  productPrice?: ConfigurableProduct
  hasBorderRight: boolean
  hasBorderTop: boolean
  hasPriority?: boolean
  className?: string
  colCount?: number
  isSlider?: boolean
  showSampleCta?: boolean
  deferHydration?: boolean
  cartId?: string
  withCioAttributes?: boolean
  cioItemType?: string
  urlSelectedSize?: string
  imageLoadStrategy?: 'lazy' | 'eager'
  hidePriceFromSuffix?: boolean
  getImageHeight?: (val: number) => void
  onSampleAddedToCart?: () => void
}

export const ProductCard = ({
  product,
  productPrice,
  hasBorderRight,
  hasBorderTop,
  hasPriority,
  className,
  getImageHeight,
  isSlider,
  showSampleCta,
  deferHydration,
  cartId,
  onSampleAddedToCart,
  withCioAttributes,
  cioItemType,
  urlSelectedSize,
  imageLoadStrategy,
  hidePriceFromSuffix,
}: Props) => {
  const router = useRouter()
  const { dataLayer } = useGTM()
  const [isHydrated, setIsHydrated] = useState(!!!deferHydration)
  const nameRef = useRef<HTMLButtonElement>(null)
  const [hoveredIndex, setHoveredIndex] = useState(0)
  const [_, { height: imgHeight }] = useElementSize()
  const { t } = useTranslation()
  const { findLabel } = useProductAttributes()

  const queryClient = useQueryClient()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ usePost: false, queryClient, locale: finalLocale })

  const relatedProductSkus = useMemo(() => {
    return [product?.sku, ...(product?.related_products?.map((related) => related?.sku) || [])]
  }, [product])

  const { data: relatedProductData, isLoading } = useProductCardDetailsQuery(
    magentoClient,
    { sku: relatedProductSkus?.[hoveredIndex] as string },
    { enabled: hoveredIndex !== 0 },
  )

  const relatedProducts = useMemo(() => {
    return (product?.related_products ?? []).filter(
      (elem): elem is ProductInterface =>
        elem != null &&
        elem.benuta_form_new === product?.benuta_form_new &&
        elem.stock_status !== 'OUT_OF_STOCK',
    )
  }, [product])

  const relatedProduct = useMemo(() => {
    return relatedProductData?.products?.items?.[0] as ConfigurableProduct | undefined
  }, [relatedProductData])

  const handleColorSelect = (index: number) => {
    setHoveredIndex(index)
  }

  const cioVariationId = useMemo(() => {
    return getCioVariationId(product)
  }, [product])

  const cioProducrPrice = useMemo(() => {
    return getCioProductPrice(productPrice?.price_range || product.price_range)
  }, [product, productPrice])

  const isFirstProduct = hoveredIndex === 0
  const firstElementOrLoading = isFirstProduct || (hoveredIndex !== 0 && isLoading)

  const activeProduct = useMemo(() => {
    return firstElementOrLoading ? product : relatedProduct
  }, [firstElementOrLoading, product, relatedProduct])

  const getProductAttribute = useCallback(
    (attributeKey: keyof ConfigurableProduct) => {
      return activeProduct?.[attributeKey]
    },
    [activeProduct],
  )

  const hasAR = useMemo(() => {
    if (firstElementOrLoading) {
      return product?.benuta_ar
    } else {
      return relatedProduct?.benuta_ar
    }
  }, [firstElementOrLoading, product?.benuta_ar, relatedProduct?.benuta_ar])

  const hasSustainability = useMemo(
    () => checkHasSustainability(getProductAttribute('benuta_test_seal') as string),
    [getProductAttribute],
  )

  const isHandmade = useMemo(
    () => checkIsHandmade(getProductAttribute('benuta_production_type') as string),
    [getProductAttribute],
  )

  const isCotton = useMemo(
    () => checkIsCotton(getProductAttribute('benuta_pile_material') as number),
    [getProductAttribute],
  )

  const isWolle = useMemo(
    () => checkIsWolle(getProductAttribute('benuta_pile_material') as number),
    [getProductAttribute],
  )

  const isWashable = useMemo(
    () => checkIsWashable(getProductAttribute('benuta_waschbar') as number),
    [getProductAttribute],
  )

  const backgroundImage = useMemo(() => {
    if (!product) return null

    const images = product?.media_gallery
      ?.filter((item) => !item?.disabled)
      .find((item) => item?.position === 1)
    return images
  }, [product])

  const getBackgroundImage = useMemo(() => {
    if (isFirstProduct) {
      return backgroundImage?.url
    }

    if (isLoading) {
      return '/images/fallback_product.avif'
    }

    const galleryIndex = activeProduct?.media_gallery?.findIndex((el) => el?.position === 1)

    if (galleryIndex !== undefined && galleryIndex >= 0) {
      return activeProduct?.media_gallery?.[galleryIndex]?.url
    }

    return null
  }, [isFirstProduct, isLoading, activeProduct, backgroundImage])

  const mainImages = useMemo(() => {
    return [
      product?.image?.url,
      ...(product?.related_products?.map((related) => related?.image?.url) || []),
    ]
  }, [product])

  const getMainImage = useMemo(() => {
    const currentImage = mainImages?.[hoveredIndex]

    if (currentImage?.includes('placeholder')) {
      return '/images/fallback_product.avif'
    }

    return currentImage
  }, [mainImages, hoveredIndex])

  useEffect(() => {
    getImageHeight?.(imgHeight)
  }, [imgHeight, getImageHeight])

  useEffect(() => {
    if (router.isReady && !isHydrated && productPrice) setIsHydrated(true)
  }, [router.isReady, productPrice, isHydrated])

  const onSendDataLayer = (eventName: string) => {
    const relatedProductMinimumPrice = relatedProduct?.price_range?.minimum_price
    const relatedProductPrice =
      hoveredIndex !== 0 && !isLoading
        ? relatedProductMinimumPrice?.final_price?.value !==
          relatedProductMinimumPrice?.regular_price?.value
          ? relatedProductMinimumPrice?.final_price
          : relatedProductMinimumPrice?.regular_price
        : undefined

    const price = productPrice
      ? productPrice.price_range?.minimum_price?.final_price?.value !==
        productPrice.price_range?.minimum_price?.regular_price?.value
        ? productPrice.price_range?.minimum_price?.final_price
        : productPrice.price_range?.minimum_price?.regular_price
      : product.price_range?.minimum_price?.final_price?.value !==
        product.price_range?.minimum_price?.regular_price?.value
      ? product.price_range?.minimum_price?.final_price
      : product.price_range?.minimum_price?.regular_price
    dataLayer(DataLayerEnum.EVENT, eventName, {
      dataLayer: {
        ecommerce: {
          currency: relatedProductPrice?.currency || price?.currency || 'EUR',
          items: [
            {
              item_id: relatedProduct?.sku || product?.sku,
              item_name: relatedProduct?.name || product.name,
              price: relatedProductPrice?.value || price?.value,
            },
          ],
        },
      },
    })
  }

  const brandType = useBrandType({ manufacturer: product?.manufacturer as number })

  const hasSalePrice = useMemo(() => {
    const productToCheck = firstElementOrLoading ? productPrice || product : activeProduct
    const regularPrice = productToCheck?.price_range?.minimum_price?.regular_price?.value
    const finalPrice = productToCheck?.price_range?.minimum_price?.final_price?.value

    return finalPrice !== regularPrice
  }, [firstElementOrLoading, productPrice, product, activeProduct])

  const isNewProduct = useMemo(() => {
    const createdAt = getProductAttribute('created_at') as string
    const createdAtDate = new Date(createdAt)
    const currentDate = new Date()
    const timeDifference = currentDate.getTime() - createdAtDate.getTime()
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

    return daysDifference <= 180
  }, [getProductAttribute])

  const hasBestseller = useMemo(() => {
    const umsatz = getProductAttribute('umsatz') as number

    return umsatz >= 0 && umsatz <= 35
  }, [getProductAttribute])

  const isSoldOut = useMemo(() => {
    return getProductAttribute('stock_status') === 'OUT_OF_STOCK'
  }, [getProductAttribute])

  const generateAltTags = useCallback(
    (product: ConfigurableProduct) => {
      const isRugProduct = product?.attribute_set_id === 115

      return isRugProduct
        ? t(
            'Rug {{Placeholder_Collection_name}} made of {{Placeholder_Material}} in {{Placeholder_Colour}} by {{Placeholder_Subbrand}}',
            {
              Placeholder_Collection_name: findLabel({
                attributeCode: 'benuta_collection',
                attributeValue: product?.benuta_collection as string,
              }),
              Placeholder_Colour: findLabel({
                attributeCode: 'benuta_color_filter',
                attributeValue: product?.benuta_color_filter as number,
              }),
              Placeholder_Material: findLabel({
                attributeCode: 'benuta_pile_material',
                attributeValue: product?.benuta_pile_material as number,
              }),
              Placeholder_Subbrand: findLabel({
                attributeCode: 'manufacturer',
                attributeValue: product?.manufacturer as number,
              }),
            },
          )
        : t(
            '{{Placeholder_Product_Name}} in {{Placeholder_First_Element_of_Design}} design by {{Placeholder_Subbrand}}',
            {
              Placeholder_Product_Name: product?.name || '',
              Placeholder_First_Element_of_Design: findLabel({
                attributeCode: 'benuta_motive_design',
                attributeValue: product?.benuta_motive_design as string,
              }).split(',')?.[0],
              Placeholder_Subbrand: findLabel({
                attributeCode: 'manufacturer',
                attributeValue: product?.manufacturer as number,
              }),
            },
          )
    },
    [findLabel, t],
  )

  const finalProductUrl = useMemo(() => {
    if (isLoading) return product?.url_key

    return getProductAttribute('url_key') || product?.url_key
  }, [isLoading, product, getProductAttribute])

  const productDetails = useMemo(() => {
    return {
      isSale: hasSalePrice,
      newProduct: isNewProduct,
      hasAR,
      hasBestseller,
      hasBadges: isNewProduct || hasSalePrice || hasBestseller,
      isSoldOut,
      backgroundImage: getBackgroundImage,
      productName: getProductAttribute('name') as string,
      productUrl: `${finalProductUrl as string}.html${
        urlSelectedSize ? `?selected_size=${urlSelectedSize}` : ''
      }#${product?.uid}`,
      sku: getProductAttribute('sku') as string,
      mainImage: getMainImage,
      altForImage: generateAltTags(activeProduct as ConfigurableProduct),
    }
  }, [
    hasAR,
    hasSalePrice,
    isNewProduct,
    hasBestseller,
    isSoldOut,
    activeProduct,
    getProductAttribute,
    finalProductUrl,
    product?.uid,
    generateAltTags,
    getBackgroundImage,
    getMainImage,
    urlSelectedSize,
  ])

  return (
    <figure
      id={product?.url_key as string}
      className={clsx(
        'relative h-full w-full border-b border-grey-border border-opacity-50 px-5',
        hasBorderRight ? 'border-r' : '',
        hasBorderTop ? 'border-t' : '',
        className,
      )}
      data-cnstrc-item={withCioAttributes && cioItemType ? cioItemType : undefined}
      data-cnstrc-item-id={withCioAttributes ? product?.sku : undefined}
      data-cnstrc-item-name={withCioAttributes ? product?.name : undefined}
      data-cnstrc-item-variation-id={withCioAttributes ? cioVariationId : undefined}
      data-cnstrc-item-price={withCioAttributes ? cioProducrPrice : undefined}
    >
      <ProductCardTop
        backgroundImage={productDetails.backgroundImage as string}
        dataLayerEvent={() => onSendDataLayer('select_item')}
        alt={generateAltTags(product)}
        productUrl={productDetails.productUrl}
        productName={productDetails.productName || ''}
        mainImage={productDetails.mainImage as string}
        hasPriority={!!hasPriority}
        imageLoadStrategy={imageLoadStrategy}
        isSlider={isSlider}
        hasAR={hasAR}
      />
      <div className="w-full pb-[15px] pt-2.5">
        <figcaption className="relative flex flex-col items-start gap-2.5">
          <div className="absolute right-0 top-0 h-6 w-6">
            <AddToWishlist
              isProductCard={true}
              trackingHandler={() => onSendDataLayer('add_to_wishlist')}
              sku={productDetails.sku as string}
              withCioAttributes
            />
          </div>
          <ColorThumbs
            product={product}
            relatedProducts={relatedProducts}
            selectedColorIndex={hoveredIndex}
            onColorSelect={handleColorSelect}
          />

          <div className="flex w-full flex-col items-start gap-2.5">
            {productDetails.hasBadges && hoveredIndex !== 0 && isLoading ? (
              <Loader height={24} />
            ) : (
              productDetails.hasBadges && (
                <ProductCardBadges
                  newBadge={productDetails.newProduct && !productDetails.isSale}
                  saleBadge={productDetails.isSale}
                  bestsellerBadge={productDetails.hasBestseller}
                />
              )
            )}
            <button
              className="w-full max-w-full grow pr-1"
              onClick={() => onSendDataLayer('select_item')}
              ref={nameRef}
            >
              <Link
                href={productDetails.productUrl}
                title={productDetails.productName as string}
                className="block max-w-full"
              >
                {isLoading && hoveredIndex !== 0 ? (
                  <Loader height={nameRef.current?.offsetHeight || 0} />
                ) : (
                  <p className="px-[2px] text-left text-[15px] font-extralight leading-[18px] md:px-0 lg:text-15-22-sg">
                    {brandType && (
                      <span className="text-[15px] leading-[18px] text-sg-dark-grey lg:text-15-22-sg">
                        {brandType.name}{' '}
                      </span>
                    )}
                    {productDetails.productName}
                  </p>
                )}
              </Link>
            </button>
            {isLoading &&
            hoveredIndex !== 0 &&
            (isWolle || isCotton || hasSustainability || isHandmade || isWashable) ? (
              <div className="h-[14px] w-full lg:h-[22px]">
                <Loader height={'100%'} />
              </div>
            ) : (
              (isWolle || isCotton || hasSustainability || isHandmade || isWashable) && (
                <FeaturesSection
                  isWolle={isWolle}
                  isCotton={isCotton}
                  hasSustainability={hasSustainability}
                  isHandmade={isHandmade}
                  isWashable={isWashable}
                />
              )
            )}
            {!showSampleCta && (
              <>
                {isHydrated &&
                  !productDetails.isSoldOut &&
                  (hoveredIndex !== 0 && isLoading ? (
                    <Loader height={22} />
                  ) : (
                    <PriceSection
                      hasSale={productDetails.isSale}
                      hideFromSuffix={hidePriceFromSuffix}
                      productPrice={hoveredIndex !== 0 ? undefined : productPrice?.price_range}
                      product={
                        hoveredIndex !== 0 ? (relatedProduct as ConfigurableProduct) : product
                      }
                    />
                  ))}
              </>
            )}
          </div>
          {!showSampleCta && product.reviews_io_score && (
            <RatingStars rating={product.reviews_io_score} />
          )}
          {showSampleCta && cartId && (
            <SampleButton
              product={product}
              cartId={cartId}
              onSampleAddedToCart={onSampleAddedToCart}
            />
          )}
        </figcaption>
      </div>
    </figure>
  )
}
