import clsx from 'clsx'
import RatingStarsStyle from './RatingStars.module.scss'

type RatingStarsProps = {
  rating?: number | string
}

export const RatingStars = ({ rating }: RatingStarsProps) => {
  return (
    <div
      className={clsx(RatingStarsStyle.Stars, 'text-[12px] lg:text-[18px]')}
      style={{ '--rating': rating } as React.CSSProperties}
    ></div>
  )
}
